export default {

    ///////////////////////////////////////////////////////////////
    data: function () {
        return {
            __NEW_DB_SERVER_PENDING_DATA: false,
            __lastK: null
        };
    },  //data

    on: {
        pageInit(e, page) {
            this.$app.on('DB_RTCHAT', this._newChatReceived);
        },
        pageBeforeRemove(){
            this.$app.off('DB_RTCHAT', this._newChatReceived);
        }

    },

    ///////////////////////////////////////////////////////////////
    methods: {
        //**************************************
        // chat methods

        initChat(){
          if (!this.messagebar) this.messagebar = this.$app.messagebar.create({
              el: '.messagebar',
              //resizePage: false,
              //maxHeight: 34
          });
          if (!this.messages) this.messages = this.$app.messages.create({
              el: '.messages',
              scrollMessages: true,
              scrollMessagesOnEdge: true,

              
              // First message rule
              firstMessageRule: function (message, previousMessage, nextMessage) {
                  // Skip if title
                  if (message.isTitle) return false;
                  /* if:
                  - there is no previous message
                  - or previous message type (send/received) is different
                  - or previous message sender name is different
                  */
                  if (!previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name) return true;
                  return false;
              },
              // Last message rule
              lastMessageRule: function (message, previousMessage, nextMessage) {
                  // Skip if title
                  if (message.isTitle) return false;
                  /* if:
                  - there is no next message
                  - or next message type (send/received) is different
                  - or next message sender name is different
                  */
                  if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
                  return false;
              },
              // Last message rule
              tailMessageRule: function (message, previousMessage, nextMessage) {
                  // Skip if title
                  if (message.isTitle) return false;
                  /* if (basically same as lastMessageRule):
                  - there is no next message
                  - or next message type (send/received) is different
                  - or next message sender name is different
                  */
                  if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
                  return false;
              }
          });

          this.readMSGfromDB();
          //this.renderCHAT();
        },

        renderCHAT(showUpdatedMsg){
            var that = this;
            if (this.messages) this.messages.clear();

            if (this.$root.FB.isAdmin()){
                //admin
                if (this.CHAT_MSGS.length == 0){
                    this.addChatHTML(`
                        <div class="ohno">
                            <img src="static/img/support.webp">
                            <br>Bienvenidos al sistema de mensajería.<br> No hay mensajes disponibles.
                        </div>            
                    `);
                    return;
                }
    
                //render
                this.CHAT_MSGS.map(function(m){
                    var msg = m.m;
                    if (m.f == 'U') msg = that.sanitizeSTR(msg)
                    that.addChatResponse_Admin(
                        (m.f == 'A'),   //from User?
                        msg,            //msg
                        false,          //title?
                        that.$root.U.microDateFormat2(new Date(m.d), true)
                    )
                })

            }else{

                //user
                if (this.CHAT_MSGS.length == 0){
                    this.addChatHTML(`
                        <div class="ohno">
                            <img src="static/img/support.webp">
                            <br>Bienvenidos al sistema de mensajería.<br> Cualquier pregunta será respondida por nuestro equipo y notificada también por email.
                        </div>            
                    `);
                    this.addChatResponse(false,'Hola.<br>¿En qué podemos ayudarle?')
                    //this.addChatResponse(false,'Bienvenido', true)
                    //this.addChatResponse(true,'Hola amigos de Lisa, necesitaría saber la cobertura por incendios está incluída.')
                    //this.addChatResponse(false,'Hola.<br>La cobertura es del 100% en caso de fuego eléctrico.')
                    //this.addChatResponse(true,'Muchas gracias por la atención')
                    //this.addChatResponse(false,'De nada<br>Aquí estamos siempre para ayudar en lo que sea necesario. Un saludo!')  
                    return;
                }
    
                //render
                this.CHAT_MSGS.map(function(m){
                    var msg = m.m;
                    if (m.f == 'U') msg = that.sanitizeSTR(msg)
                    that.addChatResponse(
                        (m.f == 'U'),   //from User?
                        msg,            //msg
                        false,          //title?
                        that.$root.U.microDateFormat2(new Date(m.d))
                    )
                })    
            }

            if (showUpdatedMsg){
                    //if (this.$el.find('LI.selItem').hasClass('__pchat')){
                        this.addChatResponse(false, 'Mensajes actualizados', true, null, 'msgCTitle')
                    //}
            }
        },

        _newChatReceived(e){
            this.__lastK = e.k;
            this.__NEW_DB_SERVER_PENDING_DATA = true;
            this.initChat();
        },

        addChatResponse(fromUser, msg, isTitle, date, cssClass){
            this.messages.addMessage({
                text: fromUser ? this.sanitizeSTR(msg) : msg,
                type: fromUser ? 'sent': 'received',
                isTitle: isTitle ? true : false,
                name: fromUser ? 'Yo' : 'LISA Seguros',
                avatar: fromUser ? '' : 'static/img/logo01.png',
                footer: date,
                cssClass: cssClass,
            }, 'append', false);                
            this.scrollToBottom();
        },
        
        //user = admin!
        addChatResponse_Admin(fromUser, msg, isTitle, date){
            this.messages.addMessage({
                text: fromUser ? msg : this.sanitizeSTR(msg),
                type: fromUser ? 'sent': 'received',
                isTitle: isTitle ? true : false,
                name: fromUser ? 'Admin' : 'Usuario',
                avatar: fromUser ? '' : 'static/img/user.webp',
                footer: date
            }, 'append', false);                
            this.scrollToBottom();
        },

        addChatHTML(h){
            this.messages.$el.append(h);
        },

        sendMyMessage(){    //-> saveMsg -> addMsg
            var text = this.messagebar.getValue().replace(/\n/g, '<br>').trim();
            if (!text.length) return;
            if (!this.$root.FB.isAdmin()) text = this.sanitizeSTR(text);
            this.messagebar.$textareaEl.addClass('xPENhid');
            this.saveMSGinDB(text);
        },

        addMessage(txt){
            this.messagebar.clear();
            this.messagebar.focus();
            this.messages.addMessage({
                text: txt,
            });
            this.scrollToBottom();
        },

        scrollToBottom(){
            //var EL = this.$el.find('.card-content')[0];
            var EL = this.messages.$el[0];
            EL.scrollTo({top:EL.scrollHeight, behavior: 'smooth'})
        },

        /*
        receiveFake(){
            var that = this;
            setTimeout(function(){
                that.addChatResponse(false,'Gracias por su mensaje.')
                that.scrollToBottom();
            },600)
        },
        */


        //DATABASE
        //**************** */
        reset_RTCHAT(isAdmin){
            if (isAdmin){
                this.$root.FB.database.ref('/RT_CHAT/ADMIN/'+this._ID).set(null);
            }else{
                this.$root.FB.database.ref('/RT_CHAT/'+this.$root.FB.getUID()+'/'+this._ID).set(null);
            }
        },

        readMSGfromDB(preventRender){
            var that = this;
            var FB = this.$root.FB;
            var isAdmin = FB.isAdmin();
            var userInChat =  FB.CACHE[this._CACHE_TABLE][this._ID]._USER_ID;
            var msgs = [];
            var dbRef = this._UPDATE_DB_PATH+'/CHAT/';
            if (isAdmin) dbRef = this._DB_TABLE+'/'+userInChat+'/'+this._ID+'/CHAT/';

            //check orphan server chats 
            var existsOfphan = FB.__PENDING_CHAT.indexOf(this._ID);
            if (existsOfphan > -1) { 
                this.__NEW_DB_SERVER_PENDING_DATA = true;
                FB.__PENDING_CHAT.splice(existsOfphan, 1);
                this.$app.emit('PENDING_RTCHAT');
            }

            //go
            if (this.__NEW_DB_SERVER_PENDING_DATA){
                //set loading mode
                this.messages.$el.addClass('_loading');

                //reset flags
                //var _ORIG_PENDING = this.$root.U.cloneO(FB.__PENDING_CHAT);
                this.reset_RTCHAT(isAdmin);
                this.__NEW_DB_SERVER_PENDING_DATA = false;

                //read msg from DB in realtime
                FB.database
                .ref(dbRef)
                .once("value", function(msg) {
                    //console.log(msg.val())
                    var dbmsgs = msg.val()
                    //save in cache
                    FB.CACHE[that._CACHE_TABLE][that._ID].CHAT = dbmsgs;
                    //go
                    Object.keys(dbmsgs || {}).map(m => msgs.push(dbmsgs[m]));
                    that.CHAT_MSGS = msgs;
                    setTimeout(() =>{that.messages.$el.removeClass('_loading')}, 1000);
                    //console.log(_ORIG_PENDING)
                    //if (!preventRender) that.renderCHAT(true);
                    //if (!preventRender) that.renderCHAT(_ORIG_PENDING.indexOf(that._ID) >- 1 );
                    if (!preventRender) that.renderCHAT(that.__lastK == that._ID);
                });
            }else{
                //get from cache
                //Object.keys(this.FORM_DATA_ORIG.CHAT || {}).map(m => msgs.push(this.FORM_DATA_ORIG.CHAT[m]));
                Object.keys(FB.CACHE[this._CACHE_TABLE][this._ID].CHAT || {}).map(m => msgs.push(FB.CACHE[that._CACHE_TABLE][that._ID].CHAT[m]));
                this.CHAT_MSGS = msgs;
                if (!preventRender) this.renderCHAT();
            }

        },

        saveMSGinDB(msg){                        
            var that = this;
            var FB = this.$root.FB;
            var userInChat =  FB.CACHE[this._CACHE_TABLE][this._ID]._USER_ID;
            var fromAdmin = FB.isAdmin();
            if (!fromAdmin) msg = this.sanitizeSTR(msg);
            var dbdest = this._UPDATE_DB_PATH+'/CHAT/';
            var M = {
                d:'DD_TIMESTAMP',
                m: msg,
                f: fromAdmin ? 'A': 'U',
                //ru: true,
                //ra: false,
                //u: fromAdmin ? FB.getUID() : FB.CACHE.INVS[this._ID]._USER_ID
            };
            if (fromAdmin) dbdest = '/' + this._DB_TABLE + '/' + userInChat + '/' + this._ID + '/CHAT/' ;

            FB._writeDB( dbdest, M, true, null, null, null, true )
            .then(function(res){
                if (res){
                    //console.log(res);
                    //save in CACHE
                    var newMsg = {};
                    newMsg[res] = M;
                    FB.CACHE[that._CACHE_TABLE][that._ID].CHAT = Object.assign(FB.CACHE[that._CACHE_TABLE][that._ID].CHAT, newMsg)
                    //add in UI
                    that.addMessage(msg);
                }else{
                    console.log('chat error')
                    console.log(res)
                }
            })
            .catch(function(error) {
                console.log(error)
                return false;
            })
            .finally(function() {
                that.messagebar.$textareaEl.removeClass('xPENhid');
            });        

        },

        resetChatUnread(){
            var that = this;
            if (!this.CACHE.CHATS) return;
            if (this.CACHE.CHATS[this._ID]){
                //delete from cache
                delete this.CACHE.CHATS[this._ID];
                //delete from DBserver
                if (fromAdmin) {
                    this.$root.FB._deleteDB('/RT_CHATS/ADMIN/'+this._ID);
                }else{
                    this.$root.FB._deleteDB('/RT_CHATS/'+this.getUID()+'/'+this._ID);
                }
            }
        },

        //https://stackoverflow.com/questions/2794137/sanitizing-user-input-before-adding-it-to-the-dom-in-javascript        
        sanitizeSTR(string) {
            const map = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#x27;',
                "/": '&#x2F;',
                '`': '&#x60;',
                '=': '&#x3D;'
            };
            const reg = /[&<>"'/`=]/ig;
            return string.replace(reg, (match)=>(map[match]));
        },




          
    }   //methods
};