
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return { _PARTNER: this.$root.FB.isAdmin() ? '_bk' : this.$root.FB.__PARTNER };
  },
  on: {
    pageInit() {
      this.$app.on('selectMenu', this.selectMenuFromExt);
      this.$app.on('DB_RTCHAT', this._newChatReceived);
      this.$app.on('PENDING_RTCHAT', this._checkPendingChat);
    },
    pageAfterIn() {
      this.startPreflight();
      this._checkPendingChat();
    },
    pageBeforeRemove() {
      this.$app.off('selectMenu', this.selectMenuFromExt);
      this.$app.off('DB_RTCHAT', this._newChatReceived);
      this.$app.off('PENDING_RTCHAT', this._checkPendingChat);
    }
  },
  methods: {
    test: function () {
      if (window.test) {
        window.test(this);
        return;
      }
      var that = this;
      that.$root.U.showLoader();
    },
    startPreflight() {
    },
    selectItem(e) {
      var o = this.$$(e.target).closest('LI');
      var forceReloadAll = o.hasClass('rtnew');
      this.hiliteItem(e);
      if (forceReloadAll) {
        this.$app.views.main.router.navigate(e.target.getAttribute('xref'), {
          reloadAll: true,
          context: { FORCE_RELOAD: forceReloadAll }
        });
      } else {
        this.$app.views.main.router.navigate(e.target.getAttribute('xref'));
      }
    },
    hiliteItem(e) {
      var o = this.$$(e.target).closest('LI');
      var forceReloadAll = o.hasClass('rtnew');
      this.unselectAll();
      o.removeClass('rtnew');
      o.addClass('selItem');
      this.closePanel();
    },
    askQ() {
      this.$app.methods.askCloseSession();
    },
    selectMenuFromExt(e) {
      var k = e;
      if (typeof k == 'string') {
        k = e;
      } else {
        k = e.target;
      }
      var o = this.$$(k).closest('LI');
      this.unselectAll();
      o.addClass('selItem');
    },
    unselectAll() {
      this.$$(this.$el).find('UL LI').removeClass('selItem');
    },
    closePanel() {
      this.$app.panel.close('left');
    },
    _newChatReceived() {
      this.$el.find('.icnReds').removeClass('xhid');
    },
    _checkPendingChat() {
      if (this.$root.FB.__PENDING_CHAT.length) {
        this.$el.find('.icnReds').removeClass('xhid');
      } else {
        this.$el.find('.icnReds').addClass('xhid');
      }
    }
  },
  id: 'a2cf7557d3',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page">\r\n\r\n    <div class="page-content pL">\r\n\r\n      <!--<div class="ATFlogo"><img src="static/img/atf3b2';
      r += c(ctx_1._PARTNER, ctx_1);
      r += '.svg"></div>-->\r\n      <div class="ATFlogo"><img style="width:100%;" src="static/img/atf5c';
      r += c(ctx_1._PARTNER, ctx_1);
      r += '.svg"></div>\r\n      <!--<div class="block-title">';
      r += Template7Helpers.T.call(ctx_1, 'ops', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>-->\r\n      <div class="mmenu list links-list no-hairlines no-hairlines-between no-chevron">\r\n        <ul>\r\n          <div class="subTT">Menú Principal</div>\r\n\r\n          ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.$root.FB.isAdmin(), {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n            <li id="m_hosu">\r\n              <a @click="hiliteItem" href="/hosu_admin/" data-view=".view-main"><i class="f7-icons">house</i>';
          r += Template7Helpers.T.call(ctx_2, 'home', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</a>\r\n            </li>\r\n            <li id="m_inv">\r\n              <a @click="hiliteItem" href="/invoices/" data-view=".view-main"><i class="f7-icons">doc</i>Seguros<div class="icnReds xhid"></div></a>\r\n            </li>\r\n            <li id="m_lis">\r\n              <a @click="hiliteItem" href="/invoicesList/" data-view=".view-main"><i class="f7-icons">doc_plaintext</i>Listado</a>\r\n            </li>\r\n            <li id="m_inc">\r\n              <a @click="hiliteItem" href="/inci_admin/" data-view=".view-main"><i class="f7-icons">question_circle</i>';
          r += Template7Helpers.T.call(ctx_2, 'inc', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</a>\r\n            </li>\r\n  \r\n          ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n          <li id="m_hosu">\r\n            <a @click="hiliteItem" href="/hosu/" data-view=".view-main"><i class="f7-icons">house</i>';
          r += Template7Helpers.T.call(ctx_2, 'home', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</a>\r\n          </li>\r\n          <li id="m_hosu">\r\n            <a @click="hiliteItem" href="/profile/" data-view=".view-main"><i class="f7-icons">person_crop_circle</i>Mi Perfil</a>\r\n          </li>\r\n          <li id="m_inv">\r\n            <a @click="hiliteItem" href="/invoices/" data-view=".view-main"><i class="f7-icons">doc</i>Mis Seguros<div class="icnReds xhid"></div></a>            \r\n          </li>\r\n          <li id="m_inc">\r\n            <a @click="hiliteItem" href="/inci/" data-view=".view-main"><i class="f7-icons">question_circle</i>';
          r += Template7Helpers.T.call(ctx_2, 'inc', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</a>\r\n          </li>\r\n          ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n\r\n          <li id="xhrs"></li>\r\n\r\n          <div class="subTT">Información</div>\r\n\r\n          <li id="hlp">\r\n            <a @click="hiliteItem" class="mn" href="/help/" data-view=".view-main"><i class="f7-icons f7i2">smallcircle_fill_circle</i>Ayuda</a>\r\n          </li>\r\n          <li id="leg">\r\n            <a @click="hiliteItem" class="mn" href="/legal/" data-view=".view-main"><i class="f7-icons f7i2">smallcircle_fill_circle</i>';
      r += Template7Helpers.T.call(ctx_1, 'legal', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</a>\r\n          </li>\r\n          <li id="ipid">\r\n            <a @click="hiliteItem" class="mn" href="/ipid/" data-view=".view-main"><i class="f7-icons f7i2">smallcircle_fill_circle</i>IPID y Coberturas</a>\r\n          </li>\r\n          <li id="exitA">\r\n            <a @click="askQ" href="#" class="mn"><i class="f7-icons f7i2">power</i>Cerrar Sesión</a>\r\n          </li>\r\n\r\n          <li id="xhrs" style="margin-top: 60px;"></li>\r\n          <!--\r\n          <li class="mnL">\r\n            Con la garantía de:<br>\r\n            <img class="mnLI" src="static/img/hiscoxB.svg"/>\r\n          </li>          \r\n          -->\r\n\r\n        </ul>\r\n      </div>\r\n\r\n\r\n\r\n      <div class="copyV">\r\n        <!--<img class="mnLI" src="static/img/ga_logo2.svg" width="90" height="42"/><br>-->\r\n        <img src="static/img/logo01.png"><br>\r\n        LISA Obra - v';
      r += c(ctx_1.$root.appVersion, ctx_1);
      r += '<br />\r\n        ';
      r += c(ctx_1.$root.getCopy(), ctx_1);
      r += '\r\n      </div>\r\n    </div>\r\n  </div>\r\n';
      return r;
    }(this);
  },
  style: `
[data-f7-a2cf7557d3] .mmenu {
    margin: var(--f7-list-margin-vertical);
}
[data-f7-a2cf7557d3] .subTT {
    color: #a3a3a3;
    text-transform: uppercase;
    font-size: 10px;
    font-family: 'ppb';
    margin-left: 10px;
    letter-spacing: 0;
}
[data-f7-a2cf7557d3] #hlp, #leg, #ipid, #exitA{
    xheight: 32px;
    height: 28px;
  }
[data-f7-a2cf7557d3] #hlp a, #leg a, #ipid a, #exitA a{
    font-size: 11px;
  }

[data-f7-a2cf7557d3] .mn{
    font-size: 12px;
  }
[data-f7-a2cf7557d3] #xhrs{
    margin-top: 20px;
    border-top: 1px solid rgba(255,255,255,.2);
    margin: 30px 15px 0 15px;    
  }
[data-f7-a2cf7557d3] .copyV {
    position: absolute;
    bottom: 3px;
    font-size: 9px;
    /*color: #fff;*/
    left: 0;
    right: 0;
    text-align: center;
    opacity: 0.3;
    font-family: "segoe ui", arial, tahoma, sans-serif;
  }
[data-f7-a2cf7557d3] .links-list a{
    padding-left: 20px;
    justify-content: flex-start;
  }
[data-f7-a2cf7557d3] .copyV IMG{
    width: 25px;;
  }
[data-f7-a2cf7557d3] .f7-icons.f7i2{
    font-size: 13px;
  }
[data-f7-a2cf7557d3] .f7-icons{
    font-size: 15px;
    margin-right: 8px;
  }

[data-f7-a2cf7557d3] .rtnew::after {
    content: " ";
    position: absolute;
    background-color: red;
    left: 4px;
    border-radius: 10px;
    width: 9px;
    height: 9px;
    display: inline-block;
    top: 10px;
  }
[data-f7-a2cf7557d3] .selItem.rtnew::after {
    left: 2px;
  }
[data-f7-a2cf7557d3] .mnL {
    font-family: var(--f7-font-familySys);
    /*color: rgb(209 162 237 / 45%);*/
    color: #a3a3a3;
    font-size: 10px;
    padding-top: 5px;
    padding-left: 20px;
    pointer-events: none;
    padding-right: 20px;
  }
[data-f7-a2cf7557d3] .mnLI{
    width: 90px !important;
    padding-bottom: 20px;
    opacity: .7;   
  }  
[data-f7-a2cf7557d3] .icnReds {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: red;
    border-radius: 50%;
    margin-left: 5px;
    margin-top: -.4rem;
}  
`,
  styleScoped: true
};
    