export default {
  data: function () {
    return {};
  },
  on: {},
  methods: {
    pageFullyLoaded() {
    }
  },
  id: 'e929b74374',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n\t\t<div class="page" data-name="askQ_01">\r\n\t\t\t\t<div class="page-content margin">\r\n                    <div class="ohno">\r\n                        askQ_01\r\n                    </div>\r\n                </div>\r\n        </div>\r\n\r\n';
      return r;
    }(this);
  },
  style: `

`,
  styleScoped: true
};