
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return { loggedIn: false };
  },
  methods: {
    login() {
      this.$setState({ loggedIn: true });
    },
    logout() {
      this.$setState({ loggedIn: false });
    }
  },
  id: '23aa35e6a0',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n    <div id="app">\r\n        ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loggedIn, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n            <div class="statusbar"></div>\r\n            <div class="panel panel-left panel-cover" id="panelLEFT">\r\n                <div class="view view-left"></div>  \r\n            </div>\r\n            <div class="appbar xhid" id="appBAR">\r\n                <div class="appbar-inner">\r\n                <div class="left">\r\n                    <a href="#" class="link icon-only panel-open" data-panel="left">\r\n                    <i class="icon material-icons">menu</i>\r\n                    </a>\r\n                </div>\r\n                <div id="topUserName" class=""></div>\r\n                <div class="right">\r\n                    <a href="javascript:app.f7.methods.askCloseSession();" class="link"><i class="f7-icons colorPower">power</i></a>\r\n                </div>\r\n                </div>\r\n            </div>    \r\n            <div class="view view-main safe-areas"></div>        \r\n        ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n        <div class="login-screen modal-in">\r\n            <div class="view view-init" id="view-auth" data-url="/auth/"></div>\r\n        </div>\r\n        ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n    </div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    