
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import myMixin from './mixin-master-master.js';
export default {
  mixins: [myMixin],
  data: function () {
    return {
      _FB_DB_LIST: this.$root.FB.isAdmin() ? 'getInvoicesList_Admin' : 'getInvoicesList',
      __ACTUAL_FILTER: null,
      miniHtxt: '',
      _deferSEL: false
    };
  },
  on: {
    pageInit() {
      this._fixSearchBar();
    },
    pageBeforeIn() {
      this.getDBdata();
    },
    pageBeforeRemove() {
    },
    pageAfterIn() {
    }
  },
  methods: {
    getDBdata(forceReload) {
      forceReload = true;
      var that = this;
      this.$root.U.showLoader();
      this.$root.FB[this._FB_DB_LIST](forceReload, true).then(function (res) {
        if (that.$root.FB.isAdmin())
          res = that.$root.FB.flatUsersData(res, 'INVS');
        if (that.__ACTUAL_FILTER == 2) {
          res = that.sortOnlyChat(res);
        } else if (that.__ACTUAL_FILTER === null) {
          res = that.simpleReverseData(res);
        }
        that._renderData(res, forceReload);
      }).catch(that._errGetDBdata);
    },
    selectItem: function selectItem(e) {
      var o = this.$$(e.target).closest('TR');
      this.actualSelected = o.attr('xitem');
      this.unselectAll();
      o.addClass('selItem');
      this.$app.views.main.router.navigate('/invDetail2/' + this.actualSelected + '/', {});
    },
    unselectAll() {
      this.$$(this.$el).find('.bigList TR').removeClass('selItem');
    },
    finallyDo() {
      var that = this;
      if (this._deferSEL) {
        this._deferSEL = false;
        this.$tick(function () {
          var __el = that.$el.find('LI.selItem');
          if (__el.length)
            __el[0].scrollIntoView({ block: 'end' });
        });
      }
      this._checkPendinChatList();
    },
    simpleReverseData(o) {
      var that = this;
      var O2 = {};
      Object.keys(o).reverse().map(function (i) {
        O2[i] = o[i];
        that.checkPendingChatRed(i, O2[i]);
      });
      return O2;
    },
    sortOnlyChat(o) {
      var that = this;
      var PENDING = this.$root.FB.__PENDING_CHAT;
      if (PENDING.length == 0)
        return {};
      if (!o || Object.keys(o).length == 0)
        return o;
      var O2 = {};
      Object.keys(o).filter(function (x) {
        return o[x]['CHAT'] && PENDING.indexOf(x) > -1;
      }).sort(function (a, b) {
        var aLast = o[a].CHAT[Object.keys(o[a].CHAT)[Object.keys(o[a].CHAT).length - 1]].d;
        var bLast = o[b].CHAT[Object.keys(o[b].CHAT)[Object.keys(o[b].CHAT).length - 1]].d;
        return bLast - aLast;
      }).map(function (i) {
        O2[i] = o[i];
        that.checkPendingChatRed(i, O2[i]);
      });
      return O2;
    },
    checkPendingChatRed(k, o) {
      if (!k || !o)
        return;
      if (this.$root.FB.__PENDING_CHAT.indexOf(k) > -1)
        o.__pchat = true;
    },
    showFilt(n) {
      this.$el.find('.smop').removeClass('smsel');
      if (n == 1) {
        this.$el.find('.smop.smop1').addClass('smsel');
        this.miniHtxt = 'Todos los seguros';
        this.__ACTUAL_FILTER = null;
      }
      if (n == 2) {
        this.$el.find('.smop.smop2').addClass('smsel');
        this.miniHtxt = 'Chat pendientes';
        this.__ACTUAL_FILTER = 2;
      }
      this._deferSEL = true;
      this.getDBdata();
    },
    _checkPendinChatList() {
      var that = this;
      this.$el.find('.bigList TR').removeClass('__pchat');
      this.$root.FB.__PENDING_CHAT.map(function (x) {
        that.$el.find('.bigList TR[xitem="' + x + '"]').addClass('__pchat');
      });
    }
  },
  id: '603ee52529',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n    <div class="page page-with-subnavbar PAD8" data-name="invmaster">\r\n\r\n        <div class="navbar navbarBIG no-hairline">\r\n          <div class="navbar-bg"></div>\r\n          <div class="navbar-inner sliding">\r\n            <div class="left bTit">';
      r += Template7Helpers.T.call(ctx_1, 'invL', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\r\n            <div class="right">\r\n              <!--\r\n                ';
      r += c(ctx_1.totalR, ctx_1);
      r += ' - \r\n              -->\r\n              <!--<a data-reload-detail="true" href="/shopDetail/-1/" class="link icon-only color-gray">\r\n              <a @click="createNew" href="#" class="link icon-only color-gray">\r\n                  <span class="badge color-blue">+</span>\r\n              </a>\r\n              -->\r\n            </div>              \r\n\r\n            <div class="subnavbar no-hairline">\r\n              <div class="subnavbar-inner search100">\r\n                <div class="xleft" style="flex-grow: 2;">\r\n                  <form class="searchbar searchbar-init no-hairline" data-remove-diacritics="true" data-search-container=".list.xlv" data-search-in="DIV">          \r\n                    <div class="searchbar-inner">\r\n                      <div class="searchbar-input-wrap">\r\n                        <input type="search" placeholder="';
      r += Template7Helpers.T.call(ctx_1, 'search', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.totalR, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' disabled ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' class="">\r\n                        <i class="searchbar-icon"></i>\r\n                        <span class="input-clear-button"></span>\r\n                      </div>\r\n                    </div>\r\n                  </form>                \r\n                </div>\r\n              </div>\r\n\r\n              <div class="smenu">\r\n                <div class="sm0"></div>\r\n                <div @click="showFilt(1)" title="Ver todos los seguros" class="smop smop1 smsel">Todos</div> \r\n                <!--<div @click="showFilt(2)" title="Mostrar facturas ya aseguradas" class="smop smop2">Aseguradas</div>-->\r\n                <div @click="showFilt(3)" title="Mostrar pendientes de pago" class="smop smop3">Pendientes</div>\r\n                <div class="icnReds icnPay xhid"></div>\r\n                <div @click="showFilt(2)" title="Mostrar chats pendientes" class="smop smop3">Chat</div>\r\n                <div class="icnReds icnChat xhid"></div>\r\n              </div>\r\n            </div>\r\n\r\n          </div>\r\n        </div>\r\n\r\n        <div class="page-content noSC">\r\n            <div class="xcard xcardmaster xtrueShadow card_fullY_SC">\r\n                <div class="card-content">     \r\n                  ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.totalR, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                  <div class="miniH">';
          r += c(ctx_2.miniHtxt, ctx_2);
          r += '</div>     \r\n                  ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n                  <div class="list media-list xlv xlvAD searchbar-found lvmaster chevron-center no-hairlines no-hairlines-between">\r\n                      ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.totalR, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                      <table class="bigList" width="100%">\r\n                        <tr class="head">\r\n                            <td>PEM</td>\r\n                            <td>Asegurado</td>\r\n                            <td>Colegio</td>\r\n                            <td class="tac" title="Archivos">A</td>\r\n                            <td class="tac" title="Chat">C</td>\r\n                            <td class="tac" title="Eliminado">E</td>\r\n                        </tr>\r\n                        ';
          r += Template7Helpers.each.call(ctx_2, ctx_2._DATA_LIST, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                        <tr key="';
              r += c(data_3 && data_3.key, ctx_3);
              r += '" @click="selectItem" xitem="';
              r += c(data_3 && data_3.key, ctx_3);
              r += '" class="';
              r += Template7Helpers.if.call(ctx_3, ctx_3._IS_DELETED, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '__isDeleted';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.__pchat, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '__pchat';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.buy, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'asegurada';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += 'nobuy pendiente';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '">\r\n                          <td>';
              r += c(ctx_3._POLICY_DATA.opem, ctx_3);
              r += '\u20AC</td>\r\n                          <td>';
              r += c(ctx_3._POLICY_DATA.oaname, ctx_3);
              r += '</td>\r\n                          <td>';
              r += c(ctx_3._POLICY_DATA.oacole, ctx_3);
              r += '</td>\r\n                          <td class="tac">';
              r += Template7Helpers.js.call(ctx_3, 'this.FILES ? Object.keys(this.FILES).length:0', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</td>\r\n                          <td class="tac litChat"></td>\r\n                          <td class="tac litDel"></td>\r\n                        </tr>\r\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                    </table>\r\n                    ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.dataLoaded, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                      <!--\r\n                      ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.dataLoaded, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                      <div class="selItemW searchnotfound">\r\n                          <img @click="createNew" src="static/img/noitems2.gif"/><br>\r\n                          ';
              r += Template7Helpers.T.call(ctx_3, 'noItem', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                      </div>\r\n                      ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                      -->\r\n                    ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n                  </div>\r\n              </div>\r\n            </div>\r\n            <div class="block searchnotfound sb3 searchbar-not-found">\r\n                <img @click="clearSearch" src="static/img/notfound.png"/><br>Ningún seguro encontrado\r\n            </div>            \r\n        </div>\r\n\r\n    </div>\r\n    \r\n';
      return r;
    }(this);
  },
  style: `
[data-f7-603ee52529] .__pchat .litChat:after {
    content: '';
    background-color: red;
    width: 8px;
    height: 8px;
    position: absolute;
    border-radius: 50px;
    margin-top: -.2rem;
}
[data-f7-603ee52529] .__isDeleted .litDel:after {
    content: '';
    background-color: black;
    width: 8px;
    height: 8px;
    position: absolute;
    border-radius: 50px;
    margin-top: -.2rem;
}

[data-f7-603ee52529] .selItem {
    background-color: #ffe5ff;
    background-image: none;
    border: none;
    border-bottom: 1px solid #e5e5e5;
}    
[data-f7-603ee52529] .page{
        padding-right: 0;
    }  
[data-f7-603ee52529] .miniArr{
    position: absolute;
    left: 0;
    bottom: 0;
}
[data-f7-603ee52529] .miniArr IMG{
  width: 105px !important;
  height: 140px !important;
}
[data-f7-603ee52529] .miniH {
  font-size: 10px;
  font-family: var(--f7-font-familyB);
  text-transform: uppercase;
  color: #aaa;
  letter-spacing: -.05em;
  padding-left: calc(var(--f7-subnavbar-inner-padding-left) + var(--f7-safe-area-right) + 0px);
  margin-bottom: -10px;
}
[data-f7-603ee52529] .page-content{
  --f7-navbar-height: 140px;    
  margin: 0 !important;
}
[data-f7-603ee52529] .subnavbar {
  flex-direction: column;
  --f7-subnavbar-height: 100px;
  /*calc(var(--f7-searchbar-input-height) + 4px)*/
}  
[data-f7-603ee52529] .subnavbar-inner{
  max-height: 48px;
  /*padding: 0 25px !important;*/
  padding: 0 16px 0 25px !important;
}
[data-f7-603ee52529] .navbar.navbarBIG .navbar-inner {
    padding-top: 0;
}
[data-f7-603ee52529] .smenu{
  text-align: left;
  width: 100%;
  padding-left:calc(var(--f7-subnavbar-inner-padding-left) + var(--f7-safe-area-right) + 2px);
  flex: 1;
  padding-top: 1rem;
}
[data-f7-603ee52529] .smop{
  font-size: 13px;
  display: inline-block;
  font-family: var(--f7-font-familyB);
  letter-spacing: -.05em;
  margin-right: 10px;
  opacity: .5;
  padding-bottom: 2px;
  cursor: pointer;
}
[data-f7-603ee52529] .sm0{
  display: inline-block;
  width: calc(var(--f7-subnavbar-inner-padding-left) + var(--f7-safe-area-right) + 2px);
}
[data-f7-603ee52529] .smsel{
  opacity: 1;
  border-bottom: 2px solid var(--o1-tx-col);;
}
[data-f7-603ee52529] .icnReds {
  display: inline-block;
    width: 7px;
    height: 7px;
    background: red;
    border-radius: 50%;
    margin-left: -10px;
    vertical-align: super;
    margin-right: 6px;
}
[data-f7-603ee52529] .smRR{
    text-align: right;
    margin-top: -.3rem;
    font-family: 'pp';
    color: #aaa;
    letter-spacing: -.02em;
    font-size: 10px;
    font-family: var(--f7-font-familySys);
}

[data-f7-603ee52529] .xlv .xitem-after:after {
    content: '€';
    font-size: 12px;
}
[data-f7-603ee52529] .asegurada {
    border-left: 2px solid transparent !important;
}
[data-f7-603ee52529] .asegurada.selItem {
    border: 2px solid var(--o1-se-col) !important;
}

[data-f7-603ee52529] .lvmaster{
  padding: 10px 0px 10px 10px;  
}
[data-f7-603ee52529] .navbar{
  margin-top: 1.5rem;  
}
[data-f7-603ee52529] .list ul{
  background: transparent;
}


`,
  styleScoped: true
};
    