export default {
  id: 'b77b8fe637',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n        <div class="panel" data-name="leftnull">        \r\n          <div class="page-content">\r\n              Null page\r\n          </div>\r\n        </div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};