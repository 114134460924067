//import $$ from 'dom7';
//import Framework7, { Template7} from 'framework7/framework7.esm.bundle.js';
import Framework7 from 'framework7/framework7.esm.bundle.js';
import Template7 from 'template7';
import FB from '../js/FB.js';
import U from '../js/UTILS.js';
import translate from 'translate.js';
import M from '../js/i18n.js';
import App from '../pages/app.f7.html';

import 'framework7/css/framework7.bundle.css';
import '../css/icons.css';
import '../css/app.css'; 
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import routes from './routes.js';

//main INIT
var appN = 'LISA_RCO';
//console.log('>M_INIT<');
U.isTraceON( window.location.hostname.startsWith('localhost') );
U.showLoader();
//var elements = document.getElementsByClassName('someClass');
FB.initFbase();      
FB.getPARTNER();      


//i18n translate 
//******************************************* */
/*
var i18n = translate(M.en);
Template7.registerHelper('T', function(txt) {
  return i18n(txt);
});
*/
U.setLang('es');
//U.setLang();  //not fixed -> Auto browser
Template7.registerHelper('T', function(txt) {
  return U.getL(txt);
});
Template7.registerHelper('B2A', function(txt) {
  return U.B2A(txt);
});
//formar number w/ 2 decimals and thousands -> USE -DE- instead of ES
Template7.registerHelper('F2', function(n) {
  return (n*1).toLocaleString('de',{minimumFractionDigits: 2, maximumFractionDigits: 2});
});
//format Date
Template7.registerHelper('D2', function(d) {
  return U.longDateFormat_CIBER(d);
});


//******************************************* */
var app = new Framework7({
  //component: App,
  root: '#app', // App root element

  name: 'LISA_RCO', // App name
  theme: 'aurora', // Automatic theme detection
  statusbar: {
    overlay: Framework7.device.ios ? Framework7.device.webView || Framework7.device.cordova : 'auto',
  },  
  routes: routes,
  touch: {
    iosTouchRipple: false,
    auroraTouchRipple: false,
  },

  data: function () {
    return {
      FB: FB,
      U: U,      
      appVersion: '1.08',
      appN: appN,
      user: {
        firstName: 'John',
        lastName: 'Clement',
      }
    };
  },

  methods: {
    //is FB logged?
    userIsLogged: function(){
      return FB.userLogged;
    },

    askCloseSession: function(){
      app.dialog.confirm(
        U.getL('exitSession'), 
        function () {
          FB.signOut();
        });
    },

    testLoader: function(){
        U.showLoader();
        setTimeout(U.hideLoader, 4000);
    },

    helloWorld: function () {
      app.dialog.alert('Hello World!');
    },

    getCopy: function(){
      var res = 'Copyright &copy; 2024 Lisa Smart Insurance';
      try{
        if (FB.GLOBALCONFIG2 && FB.GLOBALCONFIG2.copyright) res = FB.GLOBALCONFIG2.copyright;
      }catch(e){console.log(e);}
      return res;
    },

    /*
    cancelCalendar() {
      console.log('XXX');
    },
    */

  },

  panel: {
    swipe: false,
    visibleBreakpoint: 960,    
    //collapsedBreakpoint: 44
  },

  /*
  serviceWorker: {
    path: '/service-worker.js',
  },
  */

  dialog: {
    //title: appN,
    title: '<img src="static/img/atf0.svg" class="imgLOGO">',
    buttonOk: U.getL('btnOk'),
    buttonCancel: U.getL('btnCancel')
  },

  lazy: {
    placeholder: ''
  },

  popup:{
    closeOnEscape: true,
  },
  popover:{
    closeOnEscape: true,
  },

  /*
  notification: {
    //title: 'Over<i>asdasd</i>',
    //cssClass
    //icon: '<img src="static/img/logo1.svg" class="imgLOGO">',
    
  }
  */

  /*
  on: {
    pageInit: function(e, page) {
        U.L(e.app);
        //return;
         if(localStorage.loggedIn != 1 || localStorage.loggedIn == 'undefined'){
            e.app.router.navigate('/login/');
         }else{
            e.app.router.navigate('/');
         }
      }
    }     
  */

});

U.setF7(app);
app.$('.IS_LOADING').removeClass('IS_LOADING');

//after init import 'cause we need $ selector
window.$ = app.$;
//U.importJS('static/js/dynamics.min.js');  

var mainView = app.views.create('.view-main', {
  //url: '/login/',
  url: '/login0/',
  masterDetailBreakpoint: 800
}); 

var leftView = app.views.create('.view-left', {
  url: '/leftPanelEmpty/'
}); 
var panel = app.panel.create({
  el: '.panel-left'
});


function showPartnerLogo(){  
  var waterMk = app.$('.watermark img')[0];
  if (!waterMk.srcMod){
    var srcOrig = waterMk.src;
    waterMk.src = srcOrig.replace('.svg', FB.__PARTNER+'.svg');
    waterMk.srcMod = true;  
  }
  app.$('.watermark').css('display', 'block');  
}

app.on('FB_userlogged', function (isLogged) {
  if (FB._SILENT_LOGIN) {
    FB._SILENT_LOGIN = false;
    //leftView.router.navigate('/leftPanel/', {clearPreviousHistory:true, animate:false });    
    //app.panel.toggle();
    return;
  }
  if (!isLogged){
    mainView.router.navigate('/login/', {clearPreviousHistory:true, animate:false, reloadCurrent:true });
    leftView.router.navigate('/leftPanelEmpty/', {clearPreviousHistory:true, animate:false });
    //showPartnerLogo();
  }else{
    /*
    if (!FB.userProfile.validUser){
      mainView.router.navigate('/tmp/', {clearPreviousHistory:true, animate:false, reloadCurrent:true });
      leftView.router.navigate('/leftPanelEmpty/', {clearPreviousHistory:true, animate:false });
      return;
    }
    */

    if (window._wasPanelOpen) app.panel.toggle();
    window._wasPanelOpen = false;
    //mainView.router.navigate('/hosu/', {clearPreviousHistory:true, animate:false });

    //ADMIN ? ->
    if (FB.isAdmin()){
      //leftView.router.navigate('/leftPanel_Admin/', {clearPreviousHistory:true, animate:false });
      //document.body.classList.add('_ZZZZ_');
      leftView.router.navigate('/leftPanel/', {clearPreviousHistory:true, animate:false });
      mainView.router.navigate('/hosu_admin/', {clearPreviousHistory:true, animate:false });
      U.hideLoader();
      return; //end ALL
    }else{
      leftView.router.navigate('/leftPanel/', {clearPreviousHistory:true, animate:false });
    }

    //leftView.router.navigate('/leftPanel/', {clearPreviousHistory:true, animate:false });

    //check WebHook
    //https://localhost:9401/?p-ki3-4Fdw-MnAZd6EM_engtVOoBWt
    // CONFIG prefix -> ?p-ki3-4Fdw
    // INV_ID = -MnAZd6EM_engtVOoBWt    
    //console.log(FB.GLOBALCONFIG.strapipr)
    if (window.location.href.indexOf(FB.GLOBALCONFIG.strapipr) > -1){
      //webhook -> show invoice
      //var _iid = window.location.href.substr(window.location.href.indexOf(FB.GLOBALCONFIG.strapipr) + FB.GLOBALCONFIG.strapipr.length);

      let params = (new URL(document.location)).searchParams;
      let lastK = FB.GLOBALCONFIG.strapipr.indexOf('=');
      var _iid = params.get(FB.GLOBALCONFIG.strapipr.substr(1, lastK-1));
      _iid = _iid.substr(FB.GLOBALCONFIG.strapipr.length-lastK-1);
      if(_iid){
        FB.getInvoicesList()
          .then(function(){
            mainView.router.navigate('/WHK/', {clearPreviousHistory:true, animate:false, context:{IID:_iid} });        
            //mainView.router.navigate('/invoices/invoiceDetail/'+_iid+'/', {clearPreviousHistory:true, animate:false });        
            try{
              window.history.replaceState(null, null, "/");
            }catch(xx3){}
            U.hideLoader();
          })
          .catch(function(){
            //error?? -> normal start
            mainView.router.navigate('/hosu/', {clearPreviousHistory:true, animate:false });
            try{
              window.history.replaceState(null, null, "/");
            }catch(xx3){}
            U.hideLoader();
          });
          //mainView.router.navigate('/WHK/', {clearPreviousHistory:true, animate:false });        
          //U.hideLoader();
      }else{
        //error?? -> normal start
        mainView.router.navigate('/hosu/', {clearPreviousHistory:true, animate:false });
        U.hideLoader();
      }
    }else{
      //normal start
      mainView.router.navigate('/hosu/', {clearPreviousHistory:true, animate:false });
      U.hideLoader();
    }
  }
  //U.hideLoader();
});


//BACKBUTTON*******************************
//**************************************************/
//https://stackoverflow.com/questions/59983529/trying-to-disable-the-browsers-back-button
//fake backButton?
/*
history.pushState(null, null, window.location.href);
history.back();
window.onpopstate = function(){
  history.forward();
  var backButtonEvent = document.createEvent('Events');
  backButtonEvent.initEvent('backbutton', false, false);
  //document.dispatchEvent(backButtonEvent);		
  window.setTimeout(function(){document.dispatchEvent(backButtonEvent);},10);
};

document.addEventListener("backbutton", function (e) {
	e.preventDefault();
  if (app.views.main.router.currentRoute.url == '/askQ/'){
    console.log('here')
    app.views.main.router.currentPageEl.f7Component.prevBtn();
  }
}, false);
*/

//DEBUG OBLY
if (window.location.hostname.startsWith('localhost')){
  window.__Z = {};
  window.__Z.app = app;
  window.__Z.FB = FB;
  window.__Z.U = U;
}


// Login Screen Demo
/*
$$('#my-login-screen .login-button').on('click', function () {
  var username = $$('#my-login-screen [name="username"]').val();
  var password = $$('#my-login-screen [name="password"]').val();

  // Close login screen
  app.loginScreen.close('#my-login-screen');

  // Alert username and password
  app.dialog.alert('Username: ' + username + '<br>Password: ' + password);
});
*/

//preload images
var __niK = [
	'static/img/noitems3.gif',
	//'static/img/ohno.gif',
	//'static/img/inci2.gif',
	//'static/img/payok.gif',
	'static/img/alert.gif',
	'static/img/ok.svg',
	'static/img/notfound.png',
	//'static/img/bgred4.svg',
	//'static/img/pose00.gif',
	//'static/img/pose03.gif',
	//'static/img/bggreen5.svg',
	'static/img/lisa_logo_H.svg',
	'static/img/alert0.png',
	'static/img/atf5c.svg',
	'static/img/atf0.svg',
	//'static/img/price_04.webp',
	//'static/img/step02.webp',
	//'static/img/step01.webp',
  'static/img/ga_logo2.svg',
  'static/img/logo01.png',
  'static/img/contract.svg',
  'static/img/support.webp',
  'static/img/support.webp',
  'static/img/hosu01.webp',
  'static/img/hosu02.webp',
  'static/img/hosu03.webp',
	'static/img/hosu03.webp'];
__niK.forEach(function(x){
	var i = new Image();
	i.src = x;
});


