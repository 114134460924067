
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      DATA_LOADED: false,
      CAL_LIST: [],
      TOTAL_WO_MONTH_EVENTS: 0,
      ONLY_SEL_DAY: false,
      TOTALS: {
        TASKS: 0,
        TASKS_CLOSED: 0,
        PROPOSALS: 0,
        PROPOSALS_CLOSED: 0,
        WO: 0,
        WO_CLOSED: 0
      },
      _WIPMARKERS: [],
      gmap: null,
      umap: null
    };
  },
  on: {
    pageInit(e, page) {
    },
    pageAfterIn() {
      var that = this;
      this.$app.emit('selectMenu', '#m_hosu');
    },
    pageBeforeIn() {
      if (this.$app.device.desktop) {
      }
    },
    pageAfterOut() {
    }
  },
  methods: {
    getDBdata() {
    },
    goOpc(n) {
      this.$app.emit('selectMenu', '#m_inv');
      this.$app.views.main.router.navigate('/askQ_0' + n + '/', {
        reloadAll: true,
        context: { fromMaster: true }
      });
    }
  },
  id: '1f879bc424',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n\t\t<div class="page" data-name="homeSummaryADmin">\r\n\r\n\r\n\t\t\t\t<div class="page-content text-align-center">\r\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.DATA_LOADED, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                        <div class="row no-gap mh100">\r\n                            <div class="col-100 medium-35 paneLCal pd20 mh100 align-self-stretch">\r\n                                <div class="xtrueShadow xmargin mainBT sheetLine">\r\n                                    <div class="Xrow">\r\n                                        <div class="hosucard padding-half">\r\n                                            <div id="calendarM"></div>\r\n                                            \r\n                                            <div class="sep30"></div>\r\n\r\n                                            <div class="htit lmtit">';
          r += Template7Helpers.T.call(ctx_2, 'gstat', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                            <div class="row">\r\n                                                <div class="col cd02 xCP overH" @click="gotoLink(1)">\r\n                                                    <div class="bigT2">';
          r += c(ctx_2.TOTALS.shops, ctx_2);
          r += '</div>\r\n                                                    <div class="subT2">';
          r += Template7Helpers.T.call(ctx_2, 'shops', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                <div class="col cd02 xCP overH" @click="gotoLink(2)">\r\n                                                    <div class="bigT2">';
          r += c(ctx_2.TOTALS.users, ctx_2);
          r += '</div>\r\n                                                    <div class="subT2">';
          r += Template7Helpers.T.call(ctx_2, 'users', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                <div class="col cd02 xCP overH" @click="gotoLink(3)">\r\n                                                    <div class="bigT2">';
          r += c(ctx_2.TOTALS.providers, ctx_2);
          r += '</div>\r\n                                                    <div class="subT2">';
          r += Template7Helpers.T.call(ctx_2, 'provs', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                            </div>\r\n\r\n                                            <div class="sep20"></div>\r\n\r\n                                            <!-- <div class="htit lmtit">';
          r += Template7Helpers.T.call(ctx_2, 'nexte', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div> -->\r\n                                            <div class="row">\r\n                                                <div class="overH2 col cd01 ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.TOTALS.TASKS, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'redL';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="openTasks">\r\n                                                    <div class="bigT">';
          r += c(ctx_2.TOTALS.TASKS, ctx_2);
          r += '</div>\r\n                                                    <div class="subT">';
          r += Template7Helpers.T.call(ctx_2, 'tasksO', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                <div class="overH2 col cd01 ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.TOTALS.PROPS, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'redL';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="openProps">\r\n                                                    <div class="bigT">';
          r += c(ctx_2.TOTALS.PROPS, ctx_2);
          r += '</div>\r\n                                                    <div class="subT">';
          r += Template7Helpers.T.call(ctx_2, 'propsO', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                            </div>\r\n                                            \r\n                                            <div class="row">\r\n                                                <div class="overH2 col cd01" @click="openWO">\r\n                                                    <div class="bigT">';
          r += c(ctx_2.TOTALS.WO, ctx_2);
          r += '</div>\r\n                                                    <div class="subT">';
          r += Template7Helpers.T.call(ctx_2, 'woO', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                <div class="overH2 col cd01 ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.TOTALS.CLOSING, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'redL';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="openClosing">\r\n                                                    <div class="bigT">';
          r += c(ctx_2.TOTALS.CLOSING, ctx_2);
          r += '</div>\r\n                                                    <div class="subT">';
          r += Template7Helpers.T.call(ctx_2, 'clpr', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                <!--\r\n                                                <div class="col cd01 ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.TOTALS.PROPSU, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'redL';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="openPropsU">\r\n                                                    <div class="bigT">&nbsp;</div>\r\n                                                    <div class="subT">';
          r += Template7Helpers.T.call(ctx_2, 'pppr', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                -->\r\n                                            </div>\r\n                                            \r\n\r\n                                            \r\n\r\n\r\n                                        </div>\r\n                                    </div>\r\n                                </div>\r\n\r\n                            </div>\r\n                            <div class="col-100 medium-65 padSpecial">\r\n                                <div class="flex" style="padding-right: 25px;">\r\n                                    <div style="float:right">\r\n                                        <a href="javascript:app.f7.methods.askCloseSession();" class="link"><i class="f7-icons colorPower">power</i></a>\r\n                                    </div>\r\n                                    <div id="orglogo2"></div>\r\n                               </div>\r\n                                <br>home\r\n                            </div>\r\n                        </div>\r\n                    ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n\r\n                        ADMIN TIME!<br>Summary\r\n        \r\n                    ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n\t\t\t\t</div>\r\n\r\n\t\t</div>\r\n\r\n';
      return r;
    }(this);
  },
  style: `

`,
  styleScoped: true
};
    